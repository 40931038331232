.ant-layout {
   background-color: white;
   background-image: url("../assets/images/background.png");
   background-size: contain;
   background-repeat: no-repeat;
   background-position: right;
}

.ant-checkbox-checked .ant-checkbox-inner {
   background-color: #ed6623;
   border-color: #ed6623;
}

.ant-dropdown-link {
   text-decoration: none;
   color: #2c542a !important;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
   /* background-color: #ed6623;
   border-color: #ed6623; */
}

.ant-dropdown {
   /* width: 100%; */
   height: 50px;
   background: #ffffff 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 6px #00000029;
   border-radius: 10px;
   background-color: #f4f5f6;
}

.ant-dropdown-link {
   text-decoration: none;
   color: #2c542a !important;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
}

.ant-picker-input > input {
   color: #2c542a !important;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
}
.ant-input-group .ant-input {
   background-color: transparent !important;
   height: 100%;
}
.ant-input-group {
   height: 100%;
}
.ant-input-group-addon,
.ant-input-search-button {
   background: #f4f5f6 !important;
   box-shadow: none;
}
.ant-upload.ant-upload-drag {
   width: 100%;
   height: 200px;
   background: #f4f5f6 !important;
   box-shadow: 0px 0px 6px #00000029;
   border-radius: 10px;
   border: none;
}
.ant-upload.ant-upload-drag .ant-upload-drag-icon {
   font-family: "NEXA Bold";
   font-size: 20px;
   color: #2c542a;
}
.ant-btn {
   border: white;
   background-color: #ed6623;
}
.ant-select-dropdown {
   z-index: 10000;
}
.ant-btn-primary {
   border: white;
   background-color: #ed6623;
}
.ant-btn-default:hover {
   border: white;
   background-color: #ed6623;
}

.ant-btn:not([disabled]):hover {
   color: white;
   border: white;
   background-color: #ed6623;
}
@media (max-width: 768px) {
   .ant-picker {
      width: 300px;
   }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   background-color: transparent !important;
   height: 100%;
   border: none;
}
.ant-select-selection-placeholder,
.ant-select-selection-item {
   line-height: 50px !important;
}
