.header {
   background: #2c542a;
   border-radius: 10px;
   width: 100%;
   font: normal normal normal 26px/32px Nexa Bold;
   color: #ffffff;
   text-align: center;
   padding: 10px;
}
.state_name {
   text-align: left;
   font: normal normal normal 20px/24px Nexa Light;
   letter-spacing: 0px;
   color: #696969;
}

.state_value {
   text-align: right;
   font: normal normal normal 20px/24px Nexa Bold;
   color: #696969;
}
.state_increase {
   color: #51af11 !important;
   position: relative;
}
.state_increase::before {
   content: "\00BB";
   position: absolute;
   left: 0%;
   transform: rotateZ(-90deg);
}
