@font-face {
   font-family: "NEXA Black Italic";
   src: url("./NEXA-BLACK-ITALIC.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Bold Italic";
   src: url("./NEXA-BOLD-ITALIC.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Bold";
   src: url("./NEXA-BOLD.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Heavy Italic";
   src: url("./NEXA-HEAVY-ITALIC.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Light Italic";
   src: url("./NEXA-LIGHT-ITALIC.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Light";
   src: url("./NEXA-LIGHT.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Thin Italic";
   src: url("./NEXA-THIN-ITALIC.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Black";
   src: url("./NEXABLACK.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Heavy";
   src: url("./NEXAHEAVY.ttf") format("truetype");
}

@font-face {
   font-family: "NEXA Thin";
   src: url("./NEXATHIN.ttf") format("truetype");
}
