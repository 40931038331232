@import "./assets/fonts/fonts.css";
@import "antd/dist/antd.css";
/* ----------- */
/* CUSTOM PROPERTIES */
/*------------ */

:root {
   /* font families */
   --ff-nexaBlack: "NEXA Black";
   --ff-nexaBold: "NEXA Bold";
   --ff-nexaLight: "NEXA Light";
   /* colors */
   --clr-orange: #ed6623;
   --clr-green: #2c542a;
   --clr-white: #ffff;
   --clr-notWhite: #f4f4f4;
   --clr-semiWhite: #b1b1b1;
   --clr-gray: #5a5a5a;
   --clr-lightGray: #696969;
   --clr-shadowColor: #00000029;

   /* font sizes */
   --fs-16: clamp(0.5625rem, 1.6vw, 1rem);
   /* 0.5625 --> 9px ,1  --> 16px */
   --fs-17: clamp(0.6875rem, 1.8vw, 1.0625rem);
   /* 0.625 --> 10px ,1.0625  --> 17px */
   --fs-20: clamp(0.8125rem, 2.05vw, 1.25rem);
   /* 0.8125 --> 13px , 1.25  --> 20px */
   --fs-22: clamp(0.875rem, 2.1vw, 1.375rem);
   /* 0.875 --> 14px , 1.375  --> 22px */
   --fs-25: clamp(0.9375rem, 2.4vw, 1.5625rem);
   /* 0.9375 --> 15px , 1.375  --> 25px */
   --fs-26: clamp(0.9375rem, 2.5vw, 1.625rem);
   /* 0.9375 --> 15px , 1.375  --> 26px */
   --fs-32: clamp(0.97rem, 2.7vw, 2rem);
   /* 1 --> 15.5px , 2  --> 32px */
   --fs-35: clamp(1rem, 2.8vw, 2.1875rem);
   /* 1 --> 16px , 2.1875  --> 35px */
   --fs-39: clamp(1rem, 2.9vw, 2.4375rem);
   /* 1 --> 16px , 2.4375  --> 39px */
   --fs-42: clamp(1.0625rem, 3vw, 2.625rem);
   /* 1.0625 --> 17px , 2.625  --> 42px */
   --fs-45: clamp(1.0625rem, 3.2vw, 2.8125rem);
   /* 1.0625 --> 17px , 2.8125  --> 45px */
   --fs-55: clamp(1.125rem, 4vw, 3.4375rem);
   /* 1.125 --> 18px , 3.4375  --> 55px */
   --fs-65: clamp(1.25rem, 5vw, 4.0625rem);
   /*  1.25 --> 20px ,4.0625 --> 65px */
   --fs-71: clamp(1.375rem, 5.7vw, 4.4375rem);
   /* 1.375 --> 22px ,4.4375  --> 71px */
   --fs-79: clamp(1.5rem, 5.9vw, 4.9375rem);
   /* 1.5 --> 24px ,4.9375  --> 79px */
   --fs-117: clamp(2rem, 8vw, 7.3125rem);
   /* 2 --> 32px ,7.3125  --> 117px */
}

*::-webkit-scrollbar {
   width: 5px;
   height: 3px;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background: #ed6623;
}

/* ----------- */

/* CSS RESET */

/*------------ */

/* Box sizing rules */

*,
*::before,
*::after {
   box-sizing: border-box;
}

/* Remove default margin */

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
   margin: 0;
   font-weight: 400;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */

ul[role="list"],
ol[role="list"] {
   list-style: none;
}

/* Set core root defaults */

html:focus-within {
   scroll-behavior: smooth;
}
/* Set core body defaults */

body {
   min-height: 100vh;
   text-rendering: optimizeSpeed;
   overflow-x: hidden;
   font-family: var(--ff-nexaLight);
   font-size: var(--fs-20);
   line-height: normal;
}

/* A elements that don't have a class get default styles */

a:not([class]) {
   text-decoration-skip-ink: auto;
}

/* Inherit fonts for inputs and buttons as form elements do not inherit font */

input,
button,
textarea,
select {
   font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */

@media (prefers-reduced-motion: reduce) {
   html:focus-within {
      scroll-behavior: auto;
   }
   *,
   *::before,
   *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
   }
}

/* ----------- */

/* UTILITY CLASSES */
/*------------ */

/* font sizes */

.fs-16 {
   font-size: var(--fs-16);
}

.fs-17 {
   font-size: var(--fs-17);
}

/* .fs-20{
  font-size: var(--fs-20);
} */

.fs-22 {
   font-size: var(--fs-22);
}

.fs-25 {
   font-size: var(--fs-25);
}

.fs-26 {
   font-size: var(--fs-26);
}

.fs-32 {
   font-size: var(--fs-32);
}

.fs-35 {
   font-size: var(--fs-35);
}

.fs-39 {
   font-size: var(--fs-39);
}

.fs-42 {
   font-size: var(--fs-42);
}

.fs-45 {
   font-size: var(--fs-45);
}

.fs-55 {
   font-size: var(--fs-55);
}

.fs-65 {
   font-size: var(--fs-65);
}

.fs-71 {
   font-size: var(--fs-71);
}

.fs-79 {
   font-size: var(--fs-79);
}

.fs-117 {
   font-size: var(--fs-117);
}

/* font families */

.ff-nexaBlack {
   font-family: var(--ff-nexaBlack);
}

.ff-nexaBold {
   font-family: var(--ff-nexaBold);
}

/* .ff-nexaLight{
  font-family: var(--ff-nexaLight);
} */

.ff-robotoMed {
   font-family: var(--ff-robotoMed);
}

.ff-robotoReg {
   font-family: var(--ff-robotoReg);
}

/* colors */

.clr-gray {
   color: var(--clr-gray);
}

.clr-lightGray {
   color: var(--clr-lightGray);
}

.clr-lightGray {
   color: var(--clr-lightGray);
}

.clr-white {
   color: var(--clr-white);
}

.clr-semiWhite {
   color: var(--clr-semiWhite);
}

.clr-notWhite {
   color: var(--clr-notWhite);
}

.clr-orange {
   color: var(--clr-orange);
}

.clr-green {
   color: var(--clr-green);
}

.clr-shadowColor {
   color: var(--clr-shadowColor);
}

/* backgroundColor */
.bgCLR-notWhite {
   background-color: var(--clr-notWhite);
}

/* landingPage */

/* plans , right and wrong symbols */
.img-landingPage {
   width: 14px;
   aspect-ratio: 1;
   margin-right: 0.5rem;
}
.img-team {
   width: clamp(12.5rem, 20vw, 15.625rem);
   aspect-ratio: 1;
}
/* 
.logo{
  display: inline-block;
  margin-inline: 1rem;
  margin-top: 0.5rem;
} */

/* animation */
.fade-item-enter {
   opacity: 0;
}
.fade-item-enter-active {
   opacity: 1;
   transition: opacity 500ms ease-in;
}
.fade-item-exit {
   opacity: 1;
}
.fade-item-exit-active {
   opacity: 0;
   transition: opacity 500ms ease-in;
}

.offcanvas{
   background-color: transparent !important;
}

.offcanvas-header{
   justify-content: end !important;
}