.menu {
   --color: #696969;
   width: 50px;
   height: 50px;
   position: relative;
}
.menu svg {
   width: 50px;
   height: 50px;
   stroke: var(--color);
   stroke-width: 4px;
   stroke-linecap: round;
   stroke-linejoin: round;
   fill: none;
}
.menu svg path {
   transition: stroke-dasharray var(--duration, 0.85s) var(--easing, ease)
         var(--delay, 0s),
      stroke-dashoffset var(--duration, 0.85s) var(--easing, ease)
         var(--delay, 0s);
   stroke-dasharray: var(--array-1, 26px) var(--array-2, 100px);
   stroke-dashoffset: var(--offset, 126px);
   transform: translateZ(0);
}
.menu svg path:nth-child(2) {
   --duration: 0.7s;
   --easing: ease-in;
   --offset: 100px;
   --array-2: 74px;
}
.menu svg path:nth-child(3) {
   --offset: 133px;
   --array-2: 107px;
}
.menu.active svg path {
   --offset: 57px;
}
.menu.active svg path:nth-child(1),
.menu.active svg path:nth-child(3) {
   --delay: 0.15s;
   --easing: cubic-bezier(0.2, 0.4, 0.2, 1.1);
}
.menu.active svg path:nth-child(2) {
   --duration: 0.4s;
   --offset: 2px;
   --array-1: 1px;
}
.menu.active svg path:nth-child(3) {
   --offset: 58px;
}
