.Calendar {
   box-shadow: 0px 0px 36px #00000029;
   min-height: 100%;
   margin: auto;
   max-width: 100%;
}
.Calendar__day.-today {
   background-color: #2c542a;
   text-decoration: none;
   color: white !important;
}

.Calendar__monthSelectorItemText {
   font-size: 0.3em;
   padding: 0;
}

.Calendar__monthSelector {
   padding: 0;
}

.Calendar__yearSelectorText {
   font-size: 0.3em;
}

.event::before {
   transform: translateY(15px) !important;
   border-radius: 100%;
   position: fixed;
   background: #ed6623;
   height: 7px;
   content: "";
   width: 7px;
}

.Calendar__weekDays {
   color: #ed6623;
}

.Calendar__weekDay {
   color: #ed6623;
   cursor: auto !important;
   text-decoration: none !important;
}

.Calendar__header {
   color: #ed6623;
}
.Calendar__day,
.Calendar__weekDays,
.Calendar__header {
   font-family: Nexa Light;
}
.Calendar__monthText {
   font-family: "Nexa Bold" !important;
}
