.button {
   width: 100%;
   height: 50px;
   background: #ed6623;
   border-radius: 10px;
   text-align: center;
   padding: 10px;
   font-size: 23px;
   font-family: "NEXA Bold";
   border-color: #ed6623;
   transition: all 500ms ease;
   margin-bottom: 10px;
}
.button:hover,
.button:focus {
   background-color: #ed6623;
   border-color: #ed6623;
   transform: translate3d(0px, -2px, 0px);
   box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px 0px;
}

.button2 {
   width: 100%;
   height: 50px;
   background: #ed6623 0% 0% no-repeat padding-box;
   background-color: white;
   border-radius: 10px;
   opacity: 1;
   font-size: 23px;
   font-family: "NEXA Bold";
   color: #ed6623;
   border-color: #ed6623;
   margin-bottom: 50px;
 }

 @media (max-width: 768px) {
    .button{
      font-size: 15px !important;
    }
 }