
/* post card */
 .postCard .actions {
    display: flex;
    flex-direction: row;
    margin-block: 10px;
 }
 .postCard .actions .item {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 15px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
 }
 
 .postCard .post_title {
    font-family: Nexa Bold;
    font-size: 18px;
    color: #ed6623;
   text-transform: capitalize;
 }
 .postCard .post_owner {
    font-family: Nexa Light;
    font-size: 14px;
    color: #696969;
 }
 .postCard .count {
    font-family: Nexa Bold;
    font-size: 14px;
    color: #ed6623;
 }
 .postCard .count span {
    font-family: Nexa Light;
 }
 .postCard .tags {
    font-family: Nexa Light;
    font-size: 14px;
    color: #ed6623;
 }
 .postCard .tags span {
    color: #696969;
 }
 