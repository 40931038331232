.popup {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}
.popup .container {
  padding: 0;
  background-color: #fff;
  align-self: center;
  border-radius: 5px;
  box-shadow: 0 0 5px #0e0e0e;
  position: relative;
}
.popup .scrollDiv .article-img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}
.popup .container .info {
  padding: 10px 15px;
}
.popup .container .info h1 {
  color: #0e0e0e;
}
.popup .container .info p {
  color: #2e2d2d;
  white-space: pre-line;
}
.popup .container span.date {
  float: right;
  color: #757575;
  font-size: 14px;
}
.popup .container span.icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}
.scrollDiv {
  overflow: scroll;
  height: 90vh;
}
