.bg {
   height: 250px;
   width: 206px;
   background-image: url("../../assets/images/playGround.png");
   background-size: cover;
   transform: rotateX(44deg);
}

.bg .row {
   height: 100%;
   padding: 24px;
}
.bg .row .col{
    display: flex;
    justify-content: space-between;
}