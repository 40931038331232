/* sidebar */
.pro-sidebar {
   height: 100%;
   width: 100%;
   min-width: 100%;
}

.pro-sidebar > .pro-sidebar-inner {
   border-radius: 0px 30px 30px 0px;
   background: #ffffff;
   box-shadow: 0px 0px 56px #00000029;
   width: 100%;
   min-width: 100%;
}

.pro-sidebar .pro-menu .active_link{
   color: white !important;
}

.pro-sidebar-layout {
   justify-content: space-between;
}

.pro-inner-item {
   padding-block: 3px !important;
   margin-bottom: 10px;
}
.pro-menu-item {
   border-radius: 5px;
   color: rgb(149, 149, 149);
   font-family: "NEXA Light";
   position: relative;
}
.pro-inner-item::after {
   border-radius: 5px;
   background-color: #ed672b;
   content: '';
   position: absolute;
   height: 100%;
   width: 0;
   top: 0;
   left: 0;
   transition: all 500ms cubic-bezier(.5,0,.5,1);
}
.pro-inner-item:hover::after {
   width: 100%;
}

.pro-inner-item:hover span{
   color: white;
   z-index: 2;
   position: relative;
}
.pro-inner-item:hover .pro-icon svg{
   fill: #fff !important;
   stroke: #fff !important;
}
.pro-sidebar .pro-menu a:hover{
   color: white !important;
}
.pro-menu-item::before {
   content: "" !important;
}
/* //// */
.photo {
   position: relative;
   border-radius: 50%;
   width: 200px;
   height: 200px;
   border: 1px solid green;
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
}

.photo .ant-upload.ant-upload-select-picture-card {
   position: absolute;
   bottom: -10px;
   height: 200px;
   width: 100%;
   background-color: transparent;
   border: none;
}

.photo:hover .upload-photo {
   opacity: 1 !important;
}

.photo .upload-photo {
   position: absolute;
   width: 100%;
   height: 35%;
   bottom: 0px;
   color: #fff;
   text-align: center;
   padding-top: 4px;
   font-size: 16px;
   font-family: "Nexa Bold";
   background-color: #2c542a;
   opacity: 0;
   transition: all 500ms ease;
}
