.input {
   width: 100%;
   height: 50px;
   background: #f4f5f6;
   box-shadow: 0px 0px 6px #00000029;
   border-radius: 10px;
   color: #2c542a;
   border: none;
   font-family: "NEXA Bold";
   font-size: 20px;
}
.input::placeholder {
   color: #2c542a;
}
@media (max-width: 768px) {
   .input {
      /* width: 300px !important; */
      align-self: center;
      font-size: 15px !important;
      justify-self: center;
    }

    .item{
      width: 100% !important;
      
    }
}


.searchinput{
   
}