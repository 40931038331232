.bgPicture {
   background-image: linear-gradient(180deg, #00000000 0%, #0f0f0f 100%),
      url("../assets/imgs/authPic.jpeg");
   border: 1px solid #707070;
   opacity: 1;
   min-height: 100vh;
   background-size: cover;
   display: flex;
   justify-content: center;
}

.logo {
   width: 75%;
   height: auto;
   background-color: #ffffff;
   border-radius: 20px;
}

.header {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 20px;
   /* margin-bottom: 85px; */
}

.noAcc {
   width: fit-content;
   height: fit-content;
   text-align: center;
   font-family: "NEXA Bold";
   font-size: 23px;
   letter-spacing: 0px;
   color: #202020;
   opacity: 1;
   align-items: center;
}

.sign {
   width: fit-content;
   height: fit-content;
   text-align: center;
   font-family: "NEXA Bold";
   font-size: 23px;
   letter-spacing: 0px;
   color: #ed6623;
   opacity: 1;
   align-items: center;
   text-decoration: underline;
   margin-left: 10px;
}

.authTitle {
   font-family: "NEXA Bold";
   font-size: 36px;
   color: #202020;
}

.account {
   display: flex;
   flex-direction: row;
}

.remember {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}

.checkbox {
   display: flex;
   /* width: 50%; */
   justify-content: flex-start;
   text-decoration: none;
}

.forgotPass {
   display: flex;
   /* width: 50%; */
   justify-content: flex-end;
   text-decoration: none;
   color: #ed6623;
}

.button {
   width: 537px;
   height: 67px;
   background: #ed6623 0% 0% no-repeat padding-box;
   background-color: #ed6623;
   border-radius: 10px;
   opacity: 1;
   font-size: 23px;
   font-family: "NEXA Bold";
   color: #ffffff;
   margin-bottom: 50px;
   border: #ed6623;
}

.button2 {
   width: 100%;
   height: 50px;
   background: #ed6623 0% 0% no-repeat padding-box;
   background-color: white;
   border-radius: 10px;
   opacity: 1;
   font-size: 23px;
   font-family: "NEXA Bold";
   color: #ed6623;
   border-color: #ed6623;
   margin-bottom: 50px;
}

.formCol {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.input {
   width: 100%;
   height: 50px;
   background: #f4f5f6 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 6px #00000029;
   border-radius: 10px;
   opacity: 1;
   color: #2c542a;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
}

.dropdown {
   width: 100%;
   height: 50px;
   background: #ffffff 0% 0% no-repeat padding-box;
   box-shadow: 0px 0px 6px #00000029;
   border-radius: 10px;
   opacity: 1;
   color: #2c542a;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
   align-items: center;
   padding: 10px;
}

.ddLink {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   text-decoration: none;
   color: #2c542a;
   font-family: "NEXA Bold" !important;
   font-size: 23px !important;
}

.a:active {
   color: #2c542a;
}
.a:hover {
   color: #2c542a;
}

.authCodeTitle {
   font-family: "NEXA Bold";
   font-size: 40px;
   color: #2c542a;
   width: 100%;
   text-align: center;
}

.authCodeMsg {
   font-family: "NEXA Light";
   font-size: 24px;
   text-align: center;
}

.authSuccessMsg {
   font-family: "NEXA Light";
   font-size: 24px;
   width: 80%;
   text-align: center;
}

@media (max-width: 768px) {
   .center {
      /* display: flex;
      flex-direction: column;
      align-self: center;
      width: min-content; */
   }
   .noAcc {
      font-size: 15px;
   }

   .sign {
      font-size: 15px;
   }

   .authTitle {
      font-size: 23px;
   }
   .input {
      width: 300px !important;
      align-self: center;
      margin-left: 5px;
      font-size: 15px !important;
   }
   .button {
      width: 300px;
      font-size: 15px !important;
   }

   .button2 {
      font-size: 15px !important;
   }

   .form {
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .names {
      justify-content: center !important;
   }

   .logo {
      width: 300px;
      height: auto;
   }

   .dropdown {
      width: 300px;
   }

   .check {
      margin: 5px;
      width: 250px;
      height: auto;
   }

   .authCodeTitle {
      font-size: 25px;
      text-align: center;
      margin: 5px;
   }

   .authCodeMsg {
      font-size: 15px;
      text-align: center;
      margin: 5px;
   }

   .authSuccessMsg {
      font-size: 15px;
      text-align: center;
      margin: 5px;
   }
}
