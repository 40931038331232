.home {
   width: 100vw;
   background-image: url(../assets/imgs/abigail-keenan-8-s5QuUBtyM-unsplash.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   flex-direction: column;
   height: 90vh;
}

.nav-link {
   align-self: center;
   font-size: 17px;
   color: #2c542a !important;
   font-family: "NEXA Bold";
   margin-right: 5px;
}

.motto {
   display: flex;
   align-self: center;
   width: 80vw;
   flex-direction: column;
   margin-top: 5%;
}

.motto1 {
   font-family: "NEXA Black";
   font-size: 65px;
   color: #ffffff;
}

.motto2 {
   font-family: "NEXA Light";
   font-size: 65px;
   color: #ed6623;
}

.carousel {
   margin-top: 5%;
   position: absolute !important;
   bottom: 0;
   left: 0;
   width: 100%;
   display: flex;
   align-self: center;
   justify-content: center;
}


.Partners {
   margin-top: 25%;
   display: flex;
   flex-direction: column;
   width: 100%;
   height: fit-content;
   background: #ffffff 0% 0% no-repeat padding-box;

   opacity: 1;
   justify-items: center;
}

.Statistics {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: fit-content;
   background: #f4f4f4 0% 0% no-repeat padding-box;
   opacity: 1;
   justify-items: center;
   margin-bottom: 0;
}

.PText {
   text-decoration: none;
   margin-top: 5%;
   font-family: "NEXA Light";
   width: 100%;
   height: 57px;
   text-align: center;
   font-size: 45px;
   letter-spacing: 0px;
   color: #ed6623;
   opacity: 1;
   align-content: center;
}

.Image {
   margin: 50px;
}

.PContainer {
   display: flex;
   flex-wrap: wrap;
   height: fit-content;
   align-self: center;
   align-items: center;
   margin-top: 5%;
   margin-left: 0;
   justify-content: space-between;
}

/* .app {
   margin: 20px;
} */

/* .Button {
   width: 275px;
   height: 61px;
   border-radius: 10px;
   color: #ffffff;
   background-color: #ed6623;
   border-width: 0;
   align-self: center;
   font-family: "NEXA Bold";
   font-size: 17px;
} */

.cutting_edge {
   margin-top: 10%;
   width: 100%;
}

.stats {
   display: flex;
   flex-direction: row;
}

.plusSign {
   color: #2c542a;
   font-size: 117px;
   align-self: center;
}

.numbers {
   display: flex;
   color: #2c542a;
   font-size: 71px;
   font-family: "NEXA Light";
   align-self: center;
   border-width: 1px;
   border-color: #ed6623;
   border-style: solid;
   height: 15vh;
   justify-content: center;
   align-items: center;
   width: 5vw;
   margin-left: 2px;
   border-radius: 10px;
   background-color: #ffffff;
}

.statDesc {
   text-align: center;
   font: normal normal normal 22px/27px Nexa Bold;
   letter-spacing: 0px;
   color: #5a5a5a;
   opacity: 1;
   width: 25vw;
   margin-bottom: 100px;
}

.foto {
   display: flex;
   position: relative;
}

.sora {
   width: 100vw;
   margin-top: 0;
}

.vision {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   min-height: 100vh;
}

.vision2 {
   width: 90%;
}

.visionTitle {
   text-align: left;
   font-size: 25px;
   font-family: "NEXA Light";
   letter-spacing: 0px;
   color: #2c542a;
   opacity: 1;
}

.visionSubTitle {
   text-align: left;
   font-size: 55px;
   font-family: "NEXA Bold";
   letter-spacing: 0px;
   color: #ed6623;
   opacity: 1;
}

.visionParagraph {
   text-align: left;
   font-size: 25px;
   font-family: "NEXA Light";
   letter-spacing: 0px;
   color: #5a5a5a;
   opacity: 1;
}

.playerCard {
   width: 100%;
   height: 80%;
   justify-self: center;
   margin-right: 100px;
   object-fit: contain;
}

/* .mission {
   display: flex;
   flex-direction: row;
   position: relative;
   flex-wrap: wrap;
   min-height: 100vh;
} */

.missionImage {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

ul {
   list-style: none;
}

ul li::before {
   content: "\2022" !important;
   color: #ed6623;
   font-size: 55px;
   position: absolute;
   left: -30px;
   height: 100%;
   display: flex;
   align-items: center;
}

li {
   position: relative;
   font-family: "NEXA Bold";
   color: #2c542a;
   font-size: 35px;
}

.postVideo{
   width: 80%;
   position: absolute;
   right: 10%;
   top: 50%;
   transition: translateY(-50%);
   transform: translate(0px,-50%);
}

/* .missionCard {
   position: absolute;
   padding: 15px;
   bottom: 90vh;
   left: 40vw;

   width: 634px;
   height: 243px;
   border-radius: 10px !important;
   box-shadow: 0px 0px 46px #00000029;
} */

/* .missionTitle {
   font-size: 25px;
   font-family: "NEXA Light";
   color: #2c542a;
} */

/* .missionText {
   font-size: 55px;
   font-family: "NEXA Bold";
   color: #ed6623;
} */

@media (max-width: 1024px) {
   div.motto1 {
      font-size: 3rem !important;
   }
   div.motto2 {
      font-size: 3rem !important;
   }
   .Partners {
      margin-top: 30%;
   }
   /* .missionTitle {
      font-size: 15px;
      font-family: "NEXA Light";
      color: #2c542a;
   } */

   /* .missionText {
      font-size: 35px;
      font-family: "NEXA Bold";
      color: #ed6623;
   } */

   /* .missionCard {
      width: 400px;
      height: 150px;
   } */
   li {
      font-family: "NEXA Bold";
      color: #2c542a;
      font-size: 25px;
      margin-bottom: 16px;
   }

   .app {
      width: 100px;
   }

   .sora {
      width: 100vw;
   }
   .visionSubTitle {
      font-size: 25px;
   }
   .visionParagraph {
      font-size: 20px;
   }
}

/* .card {
   cursor: pointer;
} */

@media (max-width: 768px) {
   div.motto1 {
      font-size: 2rem !important;
   }
   div.motto2 {
      font-size: 2rem !important;
   }
   .Image {
      width: 25%;
      margin: 25px;
      margin-bottom: 45px;
   }

   a.nav-link {
      font-size: 13px;
   }

   ul {
      margin-top: 5px !important;
   }
   ul > li {
      font-size: 20px;
   }
   ul > li::before {
      font-size: 24px;
   }
   .missionImage {
      /* height: 40%; */
   }
   /* .missionCard {
      bottom: 60vh !important;
   } */
   .PText {
      font-size: 30px;
   }
   .plusSign {
      font-size: 30px;
   }

   .playerCard {
      width: 240px;
      margin-right: 0px;
   }
   .vision2 {
      /* width: 250px; */
      width: 100%;
   }

   .numbers {
      font-size: 30px;
      height: min-content;
      width: 20px;
      border-radius: 5px;
   }

   .statDesc {
      font-size: 15px;
   }

   /* .Button {
      width: 45%;
      height: 50px;
      font-size: 12px;
   } */

   .Partners {
      margin-top: 25%;
   }

   /* .missionCard {
      width: 200px;
      height: fit-content;
      position: absolute;
   } */

   .missionImage {
      width: 100%;
   }

   .foto {
      flex-direction: column;
   }
}

@media (max-width: 600px) {
   .carousel {
      margin-top: 25%;
      position: relative !important;
      bottom: unset !important;
   }
}
