.line {
   height: 30px;
   width: 0px;
   border-width: 1px;
   border-color: #2C542A;
   margin-right: 10px;
   border-style: solid;
   padding: 0;
}
.label {
   font-family: "Nexa Light";
   font-size: 14px;
   color: #ED6623;
   margin-bottom: 4px;
}
.value {
   color: #696969;
   font-family: "Nexa Bold";
   font-size: 14px;
}
