.eventCard {
   border-radius: 10px !important;
   background-color: unset;
   background-size: cover;
}
.eventCard .action {
   display: flex;
   height: 230px;
   justify-content: flex-start;
   align-items: flex-end;
}
.eventCard .label {
   font-family: "NEXA Light";
   font-size: 20px !important;
   color: #ffffff !important;
}

.eventCard .text {
   font-family: "NEXA Bold";
   font-size: 17px !important;
   color: #ffffff !important;
   margin-bottom: 15px;
}

.page{
   margin-top: 5%;
   padding-bottom: 5%;
}
/* post card */
.postCard {
   margin-left: 10%;
   width: 80%;
   position: relative;
   box-shadow: 0px 0px 10px #00000029;
   transition: all 0.25s cubic-bezier(0.5, 0, 0.5, 1);
}
.postCard:hover {
   box-shadow: 0px 0px 36px #00000029;
}
.postCard .actions {
   position: absolute;
   display: flex;
   flex-direction: row;
   top: 145px;
   left: 10px;
   z-index: 12;
}

.postCard .actionsShare {
   /* position: absolute; */
   display: flex;
   flex-direction: row;
   /* top: 580px;
   left: 10px; */
   z-index: 12;
}
.postCard :is(.actions,.actionsShare) .item {
   cursor: pointer;
   width: 35px;
   height: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   /* margin: 5px; */
   background-color: white;
   box-shadow: 0px 3px 6px #00000029;
   border-radius: 5px;
}

.postCard .post_title {
   font-family: Nexa Bold;
   font-size: 18px;
   color: #ed6623;
   text-transform: capitalize;
}
.postCard .post_owner {
   font-family: Nexa Light;
   font-size: 14px;
   color: #696969;
}
.postCard .count {
   font-family: Nexa Bold;
   font-size: 14px;
   color: #ed6623;
}
.postCard .count span {
   font-family: Nexa Light;
}
.postCard .tags {
   font-family: Nexa Light;
   font-size: 14px;
   color: #ed6623;
}
.postCard .tags span {
   color: #696969;
}

/* player info */
.player_info_card {
   /* background-image: url("../../assets/images/cardProfile.png"); */
   background-size: contain;
   height: 190px;
   width: 126px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
